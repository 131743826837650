* {
  box-sizing: border-box;
}
.my_wrapper{
  display: grid;
  grid-template-columns: 66.667% 33.334%;	
  padding: 30px 30px 0px 10px;
}

.my_wrapper2{
  display: grid;
  grid-template-columns: 100%;	
  padding: 4px 30px 10px 10px;
}



.my_box {
  background-color: #444;
  color: #fff;
  padding: 1em;
}

* {
box-sizing: border-box;
}

.my_pointer{
  width: 4%;
  margin-top: -10px;
}



































.container {
 padding:20px;
}
.pointer{
  width: 56%;
}

.image{
  width: 100%;
}
.text{
  font-size:24px;
}
.p{
  font-size:18px;
  padding:0px 0px 14px 0px;
}

.degree{
  font-size: 17px;
}

.title{
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  color: #0F4762;
  padding:20px 0px;   
  margin-top: -60px;
}

.red{
  background-color:#B30000;
  border-color: rgb(138, 2, 2);
  margin:8px 18px 8px 0px;
  font-size: 20px;
}
.red_empty{
  background-color: white;
  border-color: red;
  color:#A80000;
  margin:8px 0px 8px 0px;
}

.red:hover, .red:focus{
  transition: 0.4s;
  background-color:#940303 !important;
  border-color: rgb(250, 196, 196);
  box-shadow: 0 12px 16px 0 rgba(51, 1, 1, 0.24), 0 17px 50px 0 rgba(109, 3, 3, 0.19);
  
}
.red_empty:hover, .red_empty:focus{
  transition: 0.4s;
  background-color: white;
  border-color: red;
  color:#A80000;
  margin:8px 0px 8px 0px;
  box-shadow: 0 12px 16px 0 rgba(51, 1, 1, 0.24), 0 17px 50px 0 rgba(109, 3, 3, 0.19);
}

/*  //////////////////////////////////////////////////////////////////////////// */
 .wrapper2 {
  padding-top:0px;
  padding-bottom: 4px;
}
/* .row {
  width: 720px; 
  display:inline-block;
  white-space:nowrap;
}  */

.row img, .row img:first-child{
  width:70%;
  padding-top:16px;
}

.row img:first-child{
  width:50%;
  padding-top:50px;
}

.row img:not(:first-child){
  padding-left:50px;
}


.block {
  display: inline-block;
  text-align: center;
  width:160px;
  margin-top: -20px;
}

.block img {
 margin-top: 0px;
 width:auto;
 height: 130px;
}

.block p {
  padding: 10px 0px 0px 0px;
}


@media only screen and (max-width: 790px) {

  .box {
    position: absolute;
    top: 14px;
    left: 20px;
  }
  .block {
    margin-top: 0px;
  }
  .container{
    padding: 2px;
  }
  
  .image{
    height: 200px;
  }


  .title{
    font-size: 30px;
    line-height: 50px;
    color: #0F4762;
    text-align: center;
    padding: 15px 0px
  }

  .p{
    padding: 0px 14px;
  }

  .center{
    padding-top:30px;
    text-align:center;
  }


  .wrapper2 {
    padding-top:20px;
    padding-bottom: 10px;
  }


  .box {
    position: absolute;
    top: 20px;
  }

  .row img, .row img:first-child{
    width: 50%;
    padding-top: 2px;
    height: auto;
  }

  .image{
    width: 100%;
  }






  .my_wrapper{
    display: grid;
    grid-template-columns: 50% 50%;	
    padding: 20px;
  }

  .my_wrapper2{
    display: grid;
    grid-template-columns: 100%;	
    padding: 10px;
  }

  
  .my_box {
    background-color: #444;
    color: #fff;
    padding: 1em;
  }
  
  * {
  box-sizing: border-box;
  }

  .my_pointer{
    width: 15%;
  }

.title{
  font-size: 28px;
  padding: 40px 0px 10px 0px;
}


}











