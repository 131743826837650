
.wrapper {
  display: grid;
  grid-template-columns: 60px 300px;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.box {
  position: absolute;
  top: 0px;
  left: 10%;
  width:500px;
  height:100%;
  background-color: rgb(15, 71, 98, 0.85);
}

.box2 {
  position: absolute;
  top: 60px;
  left: 40px;
}

.h4{
  text-align: left;
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 54px;
  color: #f8f8f7;
  padding-left: 30px;
  word-spacing: 100vw;
}
.pointer{
  width: 110%;
}

@media only screen and (max-width: 790px) {
  .wrapper {
    display: grid;
    grid-template-columns: 40px 100px;
  }
  
  .container{
    padding: 2px;
  }
  
  .image{
    height: 200px;
  }

  .box {
    position: absolute;
    top: 0px;
    left: 10%;
    width:200px;
  }

  .box2 {
    position: absolute;
    top: 30px;
    left: 30px;
  }
  
  .pointer{
    width: 80%;
  } 
  
  .h4{
    font-size: 20px;
    line-height: 22px;
    color: #f8f8f7;
    padding-left: 2px;
    word-spacing: 100vw;
  }

}



