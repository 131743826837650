.divBackground{
  width:100%;
  height: 124px;
  background-color: #0F4762;
  color: #DCD8D0;
}

.divBackground a{
  color: #DCD8D0;
}
.divBackground a:hover{
  color: #fcf8f1;
}

.divBackgroundCenter{
  width:100%;
  height: 4px;
  background-color: #0F4762;
}

.divBorder{
  border-bottom: 2px solid rgb(231, 231, 231);
}


.footerMenu{
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  /* padding:60px 40px 40px 40px; */
  padding:5px 5px 5px 5px;
  margin-top: 60px;
  display: block;
  text-align: center !important;
}

.footerMenu_{
  text-align: center !important;
}

.footerMenuNavLink{
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 400;
  font-size: 26px !important;
  line-height: 30px;
  /* padding:0px 40px 40px 40px; */
  padding:2px 5px 5px 15px;
  margin-top: 60px;
}

.footerMenu_ a{
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  padding:40px;
  color:white;
}


.footerMenu2{
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  padding:60px 40px 40px 40px;
}

.footerMenu3{
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  padding:60px 40px 40px 40px;
}


.block img{
  padding:20px;
  width:100%;
  height:auto;
}

.blocks{
  padding:50px 0px 0px 0px;
}

.blocks span{
   font-weight: normal;
}

.blocks p{
  font-size: 17px;
  padding:0px;
  margin-bottom:7px;
  font-weight: 600;
  color:#072431 !important;
  
}


@media only screen and (max-width: 790px) {

  .blocks{
    padding:20px 0px 0px 0px;
  }

  .footerMenu{
    padding: 30px 20px 0px 20px;
    text-align: start;
    font-size: 21px;
    margin-top: -10px;
  }

  .footerMenuNavLink{
    font-family: 'Source Serif 4';
    font-style: normal;
    font-weight: 400;
    font-size: 21px !important;
    line-height: 0px;
    padding: 14px 40px 6px 11px;
    margin-top: 20px;
  }

  .footerMenu_ a{
    padding:33px 6px;
    font-size: 16px;
    line-height: 1.3;
   
  }

  .footerMenu2, .footerMenu3{
    padding: 10px 20px 0px 20px;
    font-size: 21px;
  }
  .divBorder{
    padding-top:2px;
  }

  .block img{
    padding:10px;
    width:100%;
    height:auto;
  }
}

