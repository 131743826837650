
.image{
  width: 100%;
  height: 560px;
}

.image4{
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}

.box {
  position: absolute;
  top: 0px;
  left: 10%;
  width:500px;
  height:100%;
  background-color: rgb(15, 71, 98, 0.85);
}

.h4Carousel h1{
  padding: 100px 0px 10px 30px;
  text-align: left;
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 64px;
  color: #DCD8D0;
}

/* .h4Carousel h1{
  padding: 100px 0px 10px 30px;
  text-align: left;
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 64px;
  color: #DCD8D0;
} */

.pCarousel{
  padding:30px;
  text-align: left;
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: #DCD8D0 !important;
}


@media only screen and (max-width: 790px) {
  .image{
    height: 200px;
  }
  .image4{
    width: 80%;
  }

  .box {
    position: absolute;
    top: 0px;
    left: 10%;
    width:300px;
  }
  .h4Carousel h1{
    padding: 20px 0px 10px 20px;
    font-weight: 500;
    font-size: 28px;
    line-height: 0px;
  }
  .pCarousel{
    padding:12px 20px 2px 20px;
    font-weight: 400;
    font-size: 18px;
  }
}



