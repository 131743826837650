* {
  box-sizing: border-box;
}

.wrapper {
  display: grid;
  grid-template-columns: 60px 300px;
}

.wrapper2 {
  display:none;
  padding-top:70px;
  padding-bottom: 10px;
}


.container:hover .image {
  cursor: pointer;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%); 
}

.container:hover .wrapper2 {
  display:block;
  animation: fade-in 2s;
}

.container:not(:hover) .wrapper2.did-fade-in {
  display: none;
  animation: fade-out 2s;
}

.row {
  width: 720px;
  display:inline-block;
  white-space:nowrap;
}

.row img, .row img:first-child{
  width:18%;
  padding-top:16px;
}

.row img:first-child{
  width:11.3%;
  padding-top:16px;
}

.row img:not(:first-child){
  padding-left:50px;
}


.image{
  width: 100%;
}
.pointer{
  width: 76%;
}

.image4{
  width: 20%;
  display: block;
  padding:10px;
}


.box {
  position: absolute;
  top: 30px;
  left: 30px;
}

.h4{
  text-align: left;
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 36px;
  color: #932A10;
  padding-left: 6px;
  word-spacing: 100vw;
}

.h5{
  text-align: left;
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 36px;
  color: #1C1C12;
  padding-left: 6px;
  word-spacing: 100vw;
}




@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}




@media only screen and (max-width: 790px) {
  .box {
    position: absolute;
    top: 14px;
    left: 20px;
  }

  .container{
    padding: 2px;
  }
  
  .image{
    height: 200px;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 40px 200px;
  }
  .wrapper2 {
    padding-top:20px;
    padding-bottom: 10px;
  }
  .h5{
    font-size: 20px;
  }
  .row {
    width: 500px;
  }
  .row img:first-child{
    width:8.3%;
    padding-top:2px;
  }

  .box {
    position: absolute;
    top: 20px;
  }
  .h4{
    text-align: left;
    font-family: 'Source Serif 4';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #f8f8f7;
    padding-left: 2px;
    word-spacing: 100vw;
  }

  .row img:not(:first-child){
    padding-left:14px;
    width: 12%;
  }
  .row img, .row img:first-child{
    width:9%;
    padding-top:2px;
  }
}
