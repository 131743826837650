* {
  box-sizing: border-box;
}

.paddingTop{
  padding-top:4rem;
}

.responsive {
  width: 70%;
  height: auto;
}
.container {
  text-align: left;
  padding:40px 30px 40px 160px;
}


/* ul {
  list-style: none;
  list-style-position: outside;
} */

/* ul li::before {
  content: "\2022";
  color: red;
  padding:20px;
} */

/* ul li {
 
  font-weight: bold;
  font-size: 60px;
} */




@media only screen and (max-width: 790px) {
  
  .responsive {
    width: 90%;
    height: auto;
  }
  .container {
    padding:40px;
  }
  ul li {
    font-size: 30px;
  }
}
