


.drawerWrapper {
  position: relative;
  height: 600px;
  overflow: hidden;
  text-align: center;
  background: #fafafa;
  border: 1px solid #ebedf0;
  border-radius: 2px;
  /* // z-index:99999; */
}

.gpsInfoWindow p{
  color:rgb(19, 19, 19);
  font-weight: bold;
  font-size:18px;
  width:170px;
}

.gpsInfoWindow p:not(:first-child){
  color:rgb(19, 19, 19);
  font-size:14px;
  font-weight: normal;
  margin-bottom: 0.2em;
}





.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
}
        
.right {
  text-align: right;
}


.weather {
  display: block;
  border-bottom: none;
  position: relative;
  color: #3d3d3e;
  font-size: 15px;
  line-height: 1.41;
  padding: 12px 0px;
}
.weather:after {
  content:"";
  background: -webkit-linear-gradient(left, rgb(247, 247, 247) 0%, rgb(190, 190, 190) 12%, rgb(105, 105, 105) 47%, rgb(53, 53, 53) 100%);
  /* background: -webkit-linear-gradient(right, rgb(247, 247, 247) 0%, rgb(190, 190, 190) 12%, rgb(105, 105, 105) 47%, rgb(29, 29, 29) 100%); */
  display: block;
  height:1px;
  width: 130px;
  position: absolute;
  bottom: 0;
}

.weather:before {
  content:"";
  /* background: -webkit-linear-gradient(left, rgb(247, 247, 247) 0%, rgb(190, 190, 190) 12%, rgb(105, 105, 105) 47%, rgb(29, 29, 29) 100%); */
  background: -webkit-linear-gradient(right, rgb(247, 247, 247) 0%, rgb(190, 190, 190) 12%, rgb(105, 105, 105) 47%, rgb(75, 74, 74) 100%);
  display: block;
  height:1px;
  width: 260px;
  position: absolute;
  bottom: 0;
}



.button{
  position: absolute;
  top: 10px;
  margin-left: 20px;
  z-index: 9999;
  background-color: rgba(7, 62, 90, 0.95);
  border-radius: 5px;
  height: 56px;
  font-size: 20px;
  padding: 10px;
  margin-top: -9px;
  border-color: #160a83;
}

.button::selection, .button:hover, .button:focus-within {
  background-color: #073e5af2;
  border-color: #073e5af2;
}

.p{
  margin-left:20px;
}


@media only screen and (max-width: 790px) {


}
