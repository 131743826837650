.goals{
  font-size: 44px;
  font-weight: 500;
  padding-top: 60px;
  padding-bottom: 2rem;
}

.containerLeft { text-align: left;}
.containerRight {text-align: right;}
.containerCenter {text-align: center;} 

.block {
  display: inline-block;
  width: 337px;
  height: 309px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  text-align: center;
}

.block img {
 margin-top: 30px;
 width: 130px;
 height: 130px;
}

.block h3 {
  margin-top: 35px;
  padding: 12px;
  font-size: 22px;
}


@media only screen and (max-width: 790px) {
  .box {
    position: absolute;
    top: 14px;
    left: 20px;
  }

  .goals{
    padding-top: 30px;
    padding-bottom: 1.2rem;
  }

  .containerLeft, .containerCenter, .containerRight {
    text-align: center;
    padding:12px;
  }
  .block {
    height: 277px;
  }
  

}
